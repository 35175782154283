import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'ngx-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.css']
})
export class LoadingComponent implements OnDestroy, OnChanges {
  style: any;
  @Input() show: boolean = false;

  @Input() color?: string;
  @Input()
  get size() {
    return this._size
  }
  set size(value: number) {
    this._size = value;
    this.style = {
      transform: `translate(-50%, -50%) scale(${value})`
    }
  }

  private _size: number = 2;
  time = 0;
  private intervalSubscription?: Subscription;


  ngOnChanges(changes: SimpleChanges) {
    if (changes['show'] && changes['show'].currentValue === true) {
      this.intervalSubscription = interval(1000).subscribe(() => {
        this.time++;
      });
    } else {
      this.time = 0;
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
    }
  }
  hideLoading() {
    this.show = false;
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  constructor() { }

}

import {Injectable} from '@angular/core';
import {HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {selectSkipCache} from '../../configurations/store/configurations.selector';
import moment from 'moment';
import {IsApiUrl} from '../../authorization/services/auth.util';
import {switchMap, take} from 'rxjs';

export const IGNORE_REDIS_CACHE = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!IsApiUrl(request)) return next.handle(request);

    if (request.context.get(IGNORE_REDIS_CACHE)) {
      return next.handle(
        request.clone({
          setHeaders: {
            'x-ignore-cache': 'true',
          },
        })
      );
    }

    return this.store.select(selectSkipCache(moment())).pipe(
      take(1),
      switchMap(skipCache =>
        skipCache === undefined
          ? next.handle(request.clone({}))
          : next.handle(
              request.clone({
                setHeaders: {
                  'x-ignore-cache': skipCache ? 'true' : 'false',
                },
              })
            )
      )
    );
  }
}

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BehaviorSubject, catchError, filter, map, Observable, switchMap, take, tap, throwError} from 'rxjs';

import {selectJwtRefreshToken, selectJwtToken} from '../store/authorization.selectors';
import {IsApiUrl} from '../services/auth.util';
import {
  ApiWrapperResponseOfKeyValuePairOfStringAndString,
  HttpClientAuth,
  RefreshTokenRequest
} from 'src/app/shared/nswag.api';
import {Router} from '@angular/router';
import {authLogout, onRefreshTokenSuccess} from "../store/authorization.actions";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private userToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);


  constructor(private store: Store, private httpClientAuth: HttpClientAuth, private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return !IsApiUrl(request)
      ? next.handle(request)
      : this.store.select(selectJwtToken).pipe(
        take(1),
        map(jwtToken => {
          return !jwtToken
            ? request
            : request.clone({
              setHeaders: {
                Authorization: `Bearer ${jwtToken}`,
              },
            })
        }
        ),
        switchMap(req => next.handle(req).pipe(
          catchError(err => {
            if (err.status === 401) {
              // nqs se refresh token kthen 401 atehere logout
              if (this.isRefreshing && req.url.includes('/api/Auth/refresh-token')) {
                this.store.dispatch(authLogout());
                return throwError(err);
              }
              // nqs eshte 401 dhe nuk eshte refresh token atehere bej refresh token
              return this.handle401Error(request, next)
            } else {
              return throwError(err);
            }
          }),
        )),

      );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.userToken.next(null);
      this.isRefreshing = true;
      return this.store.select(selectJwtRefreshToken).pipe(
        take(1),
        switchMap(refreshToken => this.httpClientAuth.refreshToken(new RefreshTokenRequest({ refreshToken: refreshToken }))),
        tap((data: ApiWrapperResponseOfKeyValuePairOfStringAndString) => {
          this.store.dispatch(
            onRefreshTokenSuccess({
              token: data.data!.key as string,
              refreshToken: data.data!.value as string,
            })
          );
          this.userToken.next(data.data!.key as string)
          this.isRefreshing = false;
        }),
        switchMap(data => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${data.data!.key as string}`,
            },
          });
          return next.handle(request);
        })
      )
    } else {
      return this.userToken.pipe(
        filter((token) => token != null),
        take(1),
        tap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          })
        }),
        switchMap(() => next.handle(request))
      );
    }
  }
}

import {createReducer, on} from '@ngrx/store';
import {
  authLoginSocialStep1Failure,
  authLoginSocialStep1Request,
  authLoginSocialStep1Success,
  authLoginStep1Failure,
  authLoginStep1Request,
  authLoginStep1Success,
  authLoginStep2Failure,
  authLoginStep2Request,
  authLoginStep2Success,
  authLogout,
  loadModulesFunctionsPermissions,
  loadModulesFunctionsPermissionsFailure,
  loadModulesFunctionsPermissionsSuccess,
  onLoginPageRefresh,
  onRefreshTokenRequest,
  onRefreshTokenSuccess,
  onRequestToken,
  onSwitchTenantFailure,
  onSwitchTenantRequest,
  onSwitchTenantSuccess,
} from './authorization.actions';
import {initialAuthorizationState} from './authorization.state';

// reduceri
export const authorizationReducer = createReducer(
  initialAuthorizationState,

  //#region <editor-fold desc="Login Step1">
  on(authLoginStep1Request, state => ({
    ...state,
    loading: true,
  })),
  on(authLoginStep1Success, (state, { companyDtos, loggedUserName }) => ({
    ...state,
    companyList: companyDtos,
    successLogin: true,
    loading: false,
    loggedUserName: loggedUserName,
  })),
  on(authLoginStep1Failure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
    step1HasError: true
  })),

  //#endregion </editor-fold>

  //#region <editor-fold desc="Login Social Step1">
  on(authLoginSocialStep1Request, state => ({
    ...state,
    loading: true,
  })),
  on(authLoginSocialStep1Success, (state, { userContracts }) => ({
    ...state,
    userContracts: userContracts,
    loading: false,
  })),
  on(authLoginSocialStep1Failure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
    socialStep1HasError: true
  })),
  //#endregion </editor-fold>

  //#region <editor-fold desc="Login Step2">
  on(authLoginStep2Request, state => ({
    ...state,
    loginStep2Success: true,
    loading: true,
  })),

  on(authLoginStep2Success, (state, { token, company, refreshToken }) => ({
    ...state,
    companyDto: company,
    token: token,
    refreshToken: refreshToken,
    loading: false,
  })),
  on(authLoginStep2Failure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  //#endregion </editor-fold>

  //#region <editor-fold desc="Logout">
  on(authLogout, state => ({
    ...state,
    token: undefined,
    companyDto: undefined,
    loading: false,
    companyList: undefined,
    refreshToken: undefined
  })),
  //#endregion </editor-fold>

  //#region <editor-fold desc="Decode Token">

  on(loadModulesFunctionsPermissions, state => ({
    ...state,
    modules: [],
    functions: [],
    permissions: [],
    configurations: [],
    isLoadingModulesFunctionsAndPermissions: true,
    loading: true
  })),
  on(
    loadModulesFunctionsPermissionsSuccess,
    (
      state,
      {
        modules,
        functions,
        permissions,
        configurations,
        isLoadingModulesFunctionsAndPermissions,
      }
    ) => ({
      ...state,
      modules: modules,
      functions: functions,
      permissions: permissions,
      configurations: configurations,
      isLoadingModulesFunctionsAndPermissions:
        isLoadingModulesFunctionsAndPermissions,
    })
  ),
  on(loadModulesFunctionsPermissionsFailure, (state, { error }) => ({
    ...state,
    error: error,
    modules: [],
    functions: [],
    permissions: [],
    isLoadingModulesFunctionsAndPermissions: false,
    isRefreshingToken: false
  })),
  //#endregion </editor-fold>

  on(onSwitchTenantRequest, state => ({
    ...state,
    loading: true,
  })),

  on(onSwitchTenantSuccess, (state, { token, company, refreshToken }) => ({
    ...state,
    companyDto: company,
    token: token,
    refreshToken: refreshToken,
    // loading: false,
  })),
  on(onSwitchTenantFailure, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(onLoginPageRefresh, state => ({
    ...state,
    companyList: undefined,
    loading: false,
  })),
  on(onRequestToken, state => ({
    ...state,
    loading: true,
  })),
  on(onRefreshTokenSuccess, (state, { token, refreshToken }) => ({
    ...state,
    token: token,
    refreshToken: refreshToken,
    isRefreshingToken: false
  })),
  on(onRefreshTokenRequest, state => ({
    ...state,
    isRefreshingToken: true
  })),
);

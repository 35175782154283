import { IStateData } from "../../shared/interfaces/state/state";
import { FixedAssetCategoryDto, GlAccountDto, ItemAccountingSchemeDto, LocationDto, UnitOfMeasureDto, VatDto } from "../../shared/nswag.api";

export const inventoryFeatureKey = 'inventory';

export const inventoryPersistKeys = ['cachedGlAccs', 'cachedLocations', 'cachedSchemes', 'cachedUnits', 'cachedVats', 'cachedParentCategory'].map(
  value => `${inventoryFeatureKey}.${value}`
);

export interface InventoryState {
  cachedLocations: IStateData<LocationDto>[],
  cachedSchemes: IStateData<ItemAccountingSchemeDto>[],
  cachedUnits: IStateData<UnitOfMeasureDto>[],
  cachedVats: IStateData<VatDto>[],
  cachedParentCategory: IStateData<FixedAssetCategoryDto>[],
  cachedGlAccs: IStateData<GlAccountDto>[],

}

export const initialInventoryState: InventoryState = {
  cachedLocations: [],
  cachedSchemes: [],
  cachedUnits: [],
  cachedVats: [],
  cachedGlAccs: [],
  cachedParentCategory: []
};


import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dashboardFeatureKey, DashboardState } from './dashboard.state';

export const selectFeature =
  createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const selectTestString = createSelector(
  selectFeature,
  (state: DashboardState) => state.test
);
export const selectLanguage = createSelector(
  selectFeature,
  (state: DashboardState) => state.language ?? 'sq'
);
export const selectSidebarState = createSelector(
  selectFeature,
  (state: DashboardState) => state.openedSidebar
);

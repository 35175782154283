import 'hammerjs';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, isDevMode, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtInterceptor } from 'src/app/authorization/interceptors/jwt-interceptor';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { TenantInterceptor } from './authorization/interceptors/tenant-interceptor';
import { rootReducers } from './state/store.index';
import { CommonModule } from '@angular/common';
import { debug, storageMetaReducer } from './state/meta-reducers.index';
import { authorizationFeatureKey, authorizationPersistKeys, } from './authorization/store/authorization.state';
import { dashboardFeatureKey, dashboardPersistKeys, } from './dashboard/store/dashboard.state';
import {
    API_BASE_URL,
    HttpClientAuth,
    HttpClientCompanyOptions,
    HttpClientContract,
    HttpClientCostingCenter,
    HttpClientCurrencies,
    HttpClientCustomer,
    HttpClientEmployee,
    HttpClientGeneric,
    HttpClientPermission,
    HttpClientRole,
    HttpClientSalesInvoice,
    HttpClientSalesOffer,
    HttpClientSalesOrder,
    HttpClientSalesRequest,
    HttpClientUser,
} from './shared/nswag.api';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './authorization/guards/logged-in.guard';
import { AuthorizationEffects } from './authorization/store/authorization.effects';
import { loadModulesFunctionsPermissions } from './authorization/store/authorization.actions';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { isUserTokenPresent } from './authorization/store/authorization.selectors';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { hrFeatureKey, hrPersistKeys } from './human-resources/store/hr.state';
import { configurationsFeatureKey, configurationsPersistKeys, } from './configurations/store/configurations.state';
import { ConfigurationsEffects } from './configurations/store/configurations.effects';
import { usersFeatureKey, usersPersistKeys, } from './configurations/modules/users/store/user.state';
import { itemsFeatureKey, itemsPersistKeys, } from './inventory/modules/items/store/items.state';
import { inventoryFeatureKey, inventoryPersistKeys, } from './inventory/store/inventory.state';
import {
    ChangePasswordComponentComponent
} from './authorization/pages/change-password-component/change-password-component.component';
import { NavigationService } from './shared/services/navigation-service.service';
import { GoogleLoginProvider, SocialAuthServiceConfig, } from '@abacritt/angularx-social-login';
import { CacheInterceptor } from './shared/interceptor/cache.interceptor';
import { LanguageInterceptor } from './shared/interceptor/language.interceptor';
import { ThrowInterceptor } from "./shared/interceptor/throw.interceptor";
import { ErrorInterceptor } from 'src/error.interceptor';
import { LoadingModule } from './loading/loading.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideHotToastConfig } from "@ngneat/hot-toast";
import * as Sentry from "@sentry/angular-ivy";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () =>
            import('./authorization/authorization.module').then(
                m => m.AuthorizationModule
            ),
    },
    {
        path: 'change-password',
        component: ChangePasswordComponentComponent,
    },
    {
        path: '',
        canActivateChild: [LoggedInGuard],
        canActivate: [LoggedInGuard],
        loadChildren: () =>
            import('./admin-layout/admin-layout.module').then(
                m => m.AdminLayoutModule
            ),
    },
    { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,


        RouterModule.forRoot(routes),
        AngularSvgIconModule.forRoot(),

        // translate
        TranslateModule.forRoot({
            defaultLanguage: 'sq',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),


        // ngrx
        StoreModule.forRoot(rootReducers, {
            metaReducers: [
                debug,
                storageMetaReducer<any>(
                    [
                        ...authorizationPersistKeys,
                        ...configurationsPersistKeys,
                        ...dashboardPersistKeys,
                        ...hrPersistKeys,
                        ...inventoryPersistKeys,
                        ...itemsPersistKeys,
                        ...usersPersistKeys,

                        ...authorizationFeatureKey,
                        ...configurationsFeatureKey,
                        ...dashboardFeatureKey,
                        ...hrFeatureKey,
                        ...inventoryFeatureKey,
                        ...itemsFeatureKey,
                        ...usersFeatureKey,
                    ],
                    'root'
                ),
            ],
        }),

        EffectsModule.forRoot([AuthorizationEffects, ConfigurationsEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),

        // ng-select
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,

        AngularSvgIconModule.forRoot(),
        LoadingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerImmediately'
        })
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ThrowInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        {
            provide: API_BASE_URL,
            useValue: environment.baseUrl,
        },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: <SocialAuthServiceConfig>{
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.google.key, {
                            oneTapEnabled: false,
                        }),
                    },
                ],
            },
        },
        provideHotToastConfig({
            position: "bottom-center",
            dismissible: true,
            style: {
                background: "#333",
                color: "#fff",
            },
            closeStyle: { 'color': '#333', 'background-color': '#fff', 'opacity': 'inherit' },
            error: {
                icon: "⚠︎",
            }
        }),

        HttpClientCurrencies,
        HttpClientRole,
        HttpClientPermission,
        HttpClientEmployee,
        HttpClientAuth,
        HttpClientCustomer,
        HttpClientSalesInvoice,
        HttpClientSalesOffer,
        HttpClientSalesOrder,
        HttpClientSalesRequest,
        NavigationService,
        HttpClientGeneric,
        HttpClientUser,
        HttpClientContract,
        HttpClientCostingCenter,
        HttpClientCompanyOptions
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA],
    exports: [],
})
export class AppModule {

    constructor(
        private store: Store, // private ngSelectConfig: NgSelectConfig, // private translate: TranslateService
        private translateSvc: TranslateService,
        private router: Router
    ) {
        this.routeEvent(this.router);
        translateSvc.use(translateSvc.getDefaultLang());
        this.store.select(isUserTokenPresent)
            .pipe(
                distinctUntilChanged(),
                debounceTime(100),
                filter(isUserTokenPresent => isUserTokenPresent),
                tap(() => this.store.dispatch(loadModulesFunctionsPermissions()))
            ).subscribe();
    }


    routeEvent(router: Router) {
        router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (e.url === '/' || e.url === '/no-permission') {
                    // this.router.navigate(['/dashboard']);
                }
            }
        });
    }

}

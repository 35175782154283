import { createReducer, on } from '@ngrx/store';
import {
  languageRequest,
  sidebarChange,
  testSetDateTime,
} from './dashboard.actions';
import { initialDashboardState } from './dashboard.state';

// reduceri
export const dashboardReducer = createReducer(
  initialDashboardState,

  //#region <editor-fold desc="test">
  on(testSetDateTime, (state, { dateTime }) => ({
    ...state,
    test: dateTime,
  })),
  on(languageRequest, (state, { language }) => ({
    ...state,
    language: language,
  })),
  on(sidebarChange, (state, { opened }) => ({
    ...state,
    openedSidebar: opened,
  }))
  //#endregion </editor-fold>
);

import { ItemDto, UnitOfMeasureDto } from '../../../../shared/nswag.api';
import { EXPIRE_API_SECONDS} from 'src/app/shared/constants/api-priority.seconds';
import { IStateData } from '../../../../shared/interfaces/state/state';

export const itemsFeatureKey = 'items';

export const itemsPersistKeys = ['items'].map(
  value => `${itemsFeatureKey}.${value}`
);
export interface ItemsState {
  unitOfMeasureList?: IStateData<UnitOfMeasureDto[] | undefined>;
  items?: ItemDto[];
  error: string | undefined;
}

export const initialItemsState: ItemsState = {
  unitOfMeasureList: {
    data: undefined,
    lastUpdatedDate: undefined,
    priority: EXPIRE_API_SECONDS.UNIT_OF_MEASURES,
  },
  items: undefined,
  error: undefined,
};

import { createAction, props } from '@ngrx/store';
import { CostingCenterDto, CurrencyDto } from 'src/app/shared/nswag.api';
import { User } from './configurations.state';

export const activeCurrenciesRequest = createAction(
  '[Set] activeCurrencies request'
);
export const activeCostingCentersRequest = createAction(
  '[Set] activeCostingCenters request'
);
export const onAddCurrency = createAction('[activeCurrencies] AddCurrency');
export const onDeleteCurrency = createAction(
  '[activeCurrencies] DeleteCurrency'
);
export const activeCurrenciesSuccess = createAction(
  '[Set] activeCurrencies success',
  props<{ activeCurrencies?: CurrencyDto[] }>()
);
export const activeCostingCentersSuccess = createAction(
  '[Set] activeCostingCenters success',
  props<{ costingCenter?: CostingCenterDto[] }>()
);
export const activeCurrenciesFailure = createAction(
  '[Set] activeCurrencies fail',
  props<{
    error: string;
  }>()
);

export const activeCostingCentersFailure = createAction(
  '[Set] CostingCenters fail',
  props<{
    error: string;
  }>()
);
export const skipCacheRequest = createAction(
  '[Set] Skip Cache Change',
  props<{ skipCache: boolean, showToaster: boolean }>()
);

export const apiVersionRequest = createAction('[Set] Api Version request');

export const apiVersionSuccess = createAction(
  '[Set] Api Version success',
  props<{ apiVersion?: string }>()
);
export const apiVersionFailure = createAction(
  '[Set] Api Version request fail',
  props<{
    error: string;
  }>()
);

export const cacheUser = {
  ById: createAction('[Get User] Cache User By Id', props<{ id: string }>()),
  ByIdSuccess: createAction(
    '[Get User] Cache User By Id Success',
    props<{ user: User }>()
  ),
  ByIdFailure: createAction('[Get User] Cache User By Id Failure'),
};

export const CacheCostingCenter = {
  failure: createAction('[costingCenter] Cache Failure'),
  skip: createAction('[costingCenter] Cache Skip'),
  byId: createAction('[costingCenter] Cache By ID', props<{ costingCenterId: number }>()),
  byIdSuccess: createAction('[costingCenter] Cache By ID Success', props<{ costingCenterDto: CostingCenterDto | any }>())
}
//#endregion </editor-fold>


//Clear Cache Actions
export const ClearActiveCurrenciesCache = createAction('[Configuration] Clear ActiveCurrencies Cache');
export const ClearCachedUsersCache = createAction('[Configuration] Clear CachedUsers Cache');
export const ClearCachedCostingCentersCache = createAction('[Configuration] Clear CachedCostingCenters Cache');
export const ClearConfigurationCache = createAction('[Configuration] Clear Configuration Cache');

export const decimalPlacesRequest = createAction('[Set] Decimal Places request');

export const decimalPlacesSuccess = createAction(
  '[Set] Decimal Places success',
  props<{ decimalPlaces?: number }>()
);
export const decimalPlacesFailure = createAction(
  '[Set] Decimal Places request fail',
  props<{
    error: string;
  }>()
);

export const companyOptionsChange = createAction('companyOptionsChange');

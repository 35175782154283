import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../shared/components/loading/loading.component';
import { AngularSvgIconModule } from 'angular-svg-icon';



@NgModule({
  declarations: [LoadingComponent],
  imports: [
    CommonModule,
    AngularSvgIconModule
  ],
  exports: [LoadingComponent]
})
export class LoadingModule { }

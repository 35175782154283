import { InsuranceTaxDto, PaymentTypeDto } from 'src/app/shared/nswag.api';

export const hrFeatureKey = 'hr';

export const hrPersistKeys = ['hr'].map(value => `${hrFeatureKey}.${value}`);

export interface HrState {
  paymentTypes?: PaymentTypeDto[];
  insuraceTaxes?: InsuranceTaxDto;
}

export const initialHrState: HrState = {
  paymentTypes: undefined,
  insuraceTaxes: undefined,
};

<div
  class="w-[100vw] h-[100vh] absolute top-0 left-0 bg-gray-700 !opacity-50 !z-[99999999999999999]"
  *ngIf="show">
  <svg-icon
    src="assets/icons/devBooks_logo.svg"
    [svgClass]="'w-32 cursor-pointer'"
    class="absolute top-[calc(50%-80px)] left-[47%]"
    routerLink="/dashboard">
  </svg-icon>
  <svg-icon
    *ngIf="time > 10"
    (click)="hideLoading()"
    src="assets/icons/outline/x_.svg"
    [svgClass]="'w-32 cursor-pointer bg-white'"
    class="absolute top-[20px] left-[calc(100%-40px)]"
    routerLink="/dashboard">
  </svg-icon>

  <div class="lds-default" [ngStyle]="style">
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
    <div [style.background]="color"></div>
  </div>
</div>

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment.prod';
import * as Sentry from "@sentry/angular-ivy";

// @ts-ignore
import packageInfo from '../package.json';

const lsKey = 'sentryEnabled';
const lastLoginUserKey = 'lastLoggedInUser';
const stateRootKey = '__root';

if (!localStorage.getItem(lsKey))
  localStorage.setItem(lsKey, (environment.production ? '1' : '0'));

Sentry.init({
  dsn: "https://c7c58ef6cf31f14d74266267d9800f44@sentry.kontabiliteti.online/5",
  release: `${packageInfo.name}@${packageInfo.version}`,
  beforeSend(event) {
    const isSentryEnabled = localStorage.getItem(lsKey) == '1';
    console.log('isSentryEnabled', isSentryEnabled);
    if (!isSentryEnabled) return null;

    event.tags = event.tags || {};

    function extractContractUserName() {
      let tags = {};
      try {
        const lastLoginUser = localStorage.getItem(lastLoginUserKey);
        if (!lastLoginUserKey) return tags;
        const lastLoginUserJson = JSON.parse(lastLoginUser!);
        if (lastLoginUserJson?.contract)
          tags['contract'] = lastLoginUserJson?.contract;
        if (lastLoginUserJson?.username)
          tags['username'] = lastLoginUserJson?.username;
      } catch (e) {
        console.error('Failed to parse lastLoggedInUser', e);
      }
      return tags;
    }

    function extractTenant() {
      let tags = {};
      try {
        const stateRoot = localStorage.getItem(stateRootKey);
        if (!stateRoot) return tags;
        const stateRootJson = JSON.parse(stateRoot!);
        if (stateRootJson?.authorization?.companyDto)
        {
          const company = stateRootJson!.authorization!.companyDto;
          if (company?.nipt) tags['tenant_nipt'] = company.nipt;
          if (company?.year) tags['tenant_year'] = company.year;
          if (company?.businessCode) tags['tenant_businessCode'] = company.businessCode;
          if (company?.isDemo) tags['tenant_isDemo'] = company.isDemo;
        }
      } catch (e) {
        console.error('Failed to parse lastLoggedInUser', e);
      }
      return tags;
    }

    const cu = extractContractUserName();
    if (cu) event.tags = {...event.tags, ...cu};

    const tnt = extractTenant();
    if (tnt) event.tags = {...event.tags, ...tnt};

    return event;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.debug('Bootstrap success'))
  .catch(err => console.error(err));

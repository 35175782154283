import { BehaviorSubject, defer, finalize, Observable } from 'rxjs';

export function prepare<T>(
  callback: () => void
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> =>
    defer(() => {
      callback();
      return source;
    });
}

export function loadingOperator<T>(
  indicator: BehaviorSubject<boolean>
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => indicator.next(true)),
      finalize(() => indicator.next(false))
    );
}

export function loadingOperatorNum<T>(
  indicator: BehaviorSubject<number>
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      prepare(() => indicator.next(indicator.value + 1)),
      finalize(() => indicator.next(indicator.value - 1))
    );
}

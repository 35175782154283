import { Injectable, Injector } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { IsApiUrl } from '../../authorization/services/auth.util';
import { switchMap, take } from 'rxjs';
import { selectLanguage } from 'src/app/dashboard/store/dashboard.selectors';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private store: Store) { }

  // TODO CHECK IF REQUEST IS INTERNAL OR EXTERNAL
  intercept(request: HttpRequest<unknown>, next: HttpHandler) {

    return !IsApiUrl(request)
      ? next.handle(request)
      : this.store.select(selectLanguage).pipe(
        take(1),
        switchMap(language => {
          const lang = language === 'sq' ? 'sq-AL' : 'en';
          return next.handle(
            request.clone({
              setHeaders: {
                'Accept-Language': lang
              },
            })
          );
        })
      );
  }
}

<div class="grid grid-cols-11">
  <span
    class="flex col-span-4 items-center whitespace-nowrap rounded-l bg-gray-200 px-2 text-sm leading-normal text-gray-800"
    >{{ passwordConfig.inputLabel | translate }}</span
  >
  <input
    [type]="showPassword ? 'text' : 'password'"
    [autocomplete]="passwordConfig.autocomplete"
    [formControl]="passwordConfig.formControl"
    placeholder="{{ passwordConfig.inputPlaceholder | translate }}"
    class="border-1 col-span-6 focus:shadow-outline form-input h-10 flex-grow border-gray-300 px-3 focus:border-deval_default-300 focus:outline-none"
    [ngClass]="{
      'border-deval_success-500': passwordConfig.inputColors === true && passwordConfig.formControl.valid,
      'border-deval_danger-300': passwordConfig.inputColors === true && passwordConfig.formControl.touched && passwordConfig.formControl.invalid
    }"
  />
  <!-- eye-icon-->
  <span
    class="flex h-10 w-10 items-center justify-center rounded-r bg-gray-200 px-2 text-sm leading-normal text-gray-800"
  >
    <svg
      *ngIf="!showPassword"
      (click)="showPassword = true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-6 w-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
    <svg
      *ngIf="showPassword"
      (click)="showPassword = false"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="h-6 w-6"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  </span>
</div>

import { createAction, props } from '@ngrx/store';
import { ItemDto, UnitOfMeasureDto } from '../../../../shared/nswag.api';

//#region <editor-fold desc="Items">
export const unitOfMeasureRequest = createAction(
  '[UnitOfMeasure] UnitOfMeasure Request'
);
export const unitOfMeasureRequestSuccess = createAction(
  '[UnitOfMeasure] UnitOfMeasure Success',
  props<{
    unitOfMeasureList: UnitOfMeasureDto[];
  }>()
);
export const unitOfMeasureRequestFailure = createAction(
  '[UnitOfMeasure] UnitOfMeasure Failure',
  props<{
    error: string;
  }>()
);
//#endregion </editor-fold>
//#region <editor-fold desc="Items">
export const onItemsRequest = createAction('[Items] Items Request');
export const onItemsRequestSuccess = createAction(
  '[Items] Items Success',
  props<{
    items: ItemDto[];
  }>()
);
export const onItemsRequestFailure = createAction(
  '[Items] Items Failure',
  props<{
    error: string;
  }>()
);
//#endregion </editor-fold>


// Clear Cache Actions
export const ClearUnitsOfMeasureCache = createAction('[Items] Clear UnitsOfMeasure Cache');
export const ClearItemsCache = createAction('[Items] Clear Items Cache');
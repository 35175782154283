import { createAction, props } from '@ngrx/store';

//#region <editor-fold desc="Test">
export const testSetDateTime = createAction(
  '[Test] Set Date Time',
  props<{ dateTime?: string }>()
);
export const languageRequest = createAction(
  '[Set] language',
  props<{ language?: string }>()
);
export const sidebarChange = createAction(
  '[Set] sidebar',
  props<{ opened?: boolean }>()
);
//#endregion </editor-fold>

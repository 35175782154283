import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap, take, tap } from 'rxjs';
import { selectJwtToken } from '../../authorization/store/authorization.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class NavigationService {
  private history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
    private store: Store
  ) { }

  public startSaveHistory(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        tap(e => this.history.push((<NavigationEnd>e).urlAfterRedirects)),
        filter(e => (<NavigationEnd>e).url === '/'),
        switchMap(() => this.store.select(selectJwtToken).pipe(take(1))),
        map(token => (token ? '/dashboard' : '/auth/login')),
        tap(url => this.router.navigate([url]))
      )
      .subscribe();
  }

  public getHistory(): string[] {
    return this.history;
  }

  public goBack(): void {
    this.history = [...new Set(this.history)];
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    }
    // else {
    //   this.router.navigateByUrl('/');
    // }
  }

  public getPreviousUrl(): string {
    return this.history.length > 0 ? this.history[this.history.length - 1] : '';
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectTenantIdentifier } from '../store/authorization.selectors';
import { IsApiUrl } from '../services/auth.util';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private store: Store) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return !IsApiUrl(request)
      ? next.handle(request)
      : this.store.select(selectTenantIdentifier).pipe(
        take(1),
        map(tenantIdentifier => {
          if (!tenantIdentifier) return;
          request = request.clone({
            setHeaders: {
              'X-Tenant-Id': tenantIdentifier,
            },
          });
        }),
        switchMap(() => next.handle(request))
      );
  }
}

export const dashboardFeatureKey = 'dashboard';

export const dashboardPersistKeys = ['language', 'openedSidebar'].map(
  value => `${dashboardFeatureKey}.${value}`
);

export interface DashboardState {
  test: string | undefined;
  language: string | undefined;
  openedSidebar?: boolean;
}

export const initialDashboardState: DashboardState = {
  test: undefined,
  language: undefined,
  openedSidebar: false,
};

import moment, { Moment } from 'moment';
import { ApiPrioritySeconds } from '../../constants/api-priority.seconds';

export interface IStateData<T> extends IStateConfig {
  data: T | undefined;
}

export interface IStateConfig {
  lastUpdatedDate: Moment | undefined;
  priority: number;
}

export const GetStateData = <T>(
  input: IStateData<T> | undefined,
  now: Moment,
  interval: number,
  skipCache: boolean | undefined
) => {
  if (!now) return undefined;
  if (!input) return undefined;
  if (!input?.lastUpdatedDate) return undefined;
  const lastUpdatedDate =
    typeof input.lastUpdatedDate !== typeof moment()
      ? moment(input.lastUpdatedDate)
      : input.lastUpdatedDate;

  const i = now.diff(lastUpdatedDate, 'seconds');
  const expired = i > interval;
  if (expired) return undefined;

  if (skipCache === true) {
    const expiredOnce =
      now.diff(lastUpdatedDate, 'seconds') > ApiPrioritySeconds.once / 4;
    if (expiredOnce) return undefined;
  }
  return input.data;
};

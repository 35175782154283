import { createAction, props } from "@ngrx/store"
import { LocationDto, ItemAccountingSchemeDto, GlAccountDto, UnitOfMeasureDto, VatDto, FixedAssetCategoryDto } from "src/app/shared/nswag.api"
import {HttpContext} from "@angular/common/http";

export const CacheLocation = {
    failure: createAction('[Location] Cache Failure'),
    skip: createAction('[Location] Cache Skip'),
    byNo: createAction('[Location] Cache By No', props<{ locationNo: string, httpContext?: HttpContext }>()),
    byNoSuccess: createAction('[Location] Cache By No Success', props<{ location: LocationDto }>())
}
export const ItemAccountingScheme = {
    failure: createAction('[ItemAccountingSchemeDto] Cache Failure'),
    skip: createAction('[ItemAccountingSchemeDto] Cache Skip'),
    byNo: createAction('[ItemAccountingSchemeDto] Cache By No', props<{ schemeId: number, httpContext?: HttpContext }>()),
    byNoSuccess: createAction('[ItemAccountingSchemeDto] Cache By No Success', props<{ scheme: ItemAccountingSchemeDto }>())
}

export const CacheGlAccount = {
    failure: createAction('[GlAccountDto] Cache Failure'),
    skip: createAction('[GlAccountDto] Cache Skip'),
    byNo: createAction('[GlAccountDto] Cache By No', props<{ accountId: number, httpContext?: HttpContext }>()),
    byNoSuccess: createAction('[GlAccountDto] Cache By No Success', props<{ account: GlAccountDto }>())
}


export const CacheUnits = {
    failure: createAction('[Units] Cache Failure'),
    skip: createAction('[Units] Cache Skip'),
    byNo: createAction('[Units] Cache By No', props<{ UnitId: string, httpContext?: HttpContext }>()),
    byNoSuccess: createAction('[Units] Cache By No Success', props<{ Unit: UnitOfMeasureDto }>())
}

export const CacheVats = {
    failure: createAction('[CacheVats] Cache Failure'),
    skip: createAction('[CacheVats] Cache Skip'),
    byNo: createAction('[CacheVats] Cache By No', props<{ VatId: string, httpContext?: HttpContext }>()),
    byNoSuccess: createAction('[CacheVats] Cache By No Success', props<{ Vat: VatDto }>())
}

export const CacheParentCategory = {
    failure: createAction('[FixedAssetCategoryDto] Cache Failure'),
    skip: createAction('[FixedAssetCategoryDto] Cache Skip'),
    byNo: createAction('[FixedAssetCategoryDto] Cache By No', props<{ parentId: number, httpContext?: HttpContext }>()),
    byNoSuccess: createAction('[FixedAssetCategoryDto] Cache By No Success', props<{ parent: FixedAssetCategoryDto }>())
}

// Clear Cache Actions
export const ClearGlAccountsCache = createAction('[Inventory] Clear GlAccounts Cache');
export const ClearVatCache = createAction('[Inventory] Clear Vat Cache');
export const ClearUnitCache = createAction('[Inventory] Clear Unit Cache');
export const ClearLocationCache = createAction('[Inventory] Clear Location Cache');
export const ClearItemSchemesCache = createAction('[Inventory] Clear ItemSchemes Cache');
export const ClearInventoryCache = createAction('[Inventory] Clear Inventory Cache');


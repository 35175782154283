import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CcGetAllOrFilteredRequest,
  CompanyOptionEnum,
  DynamicPage,
  HttpClientCompanyOptions,
  HttpClientCostingCenter,
  HttpClientCurrencies,
  HttpClientGeneric,
  HttpClientUser,
} from '../../shared/nswag.api';

import { nswagCatchOperator } from '../../shared/operators/nswag-catch-operator';
import {
  activeCostingCentersFailure,
  activeCostingCentersRequest,
  activeCostingCentersSuccess,
  activeCurrenciesFailure,
  activeCurrenciesRequest,
  activeCurrenciesSuccess,
  apiVersionRequest,
  apiVersionSuccess, cacheUser,
  CacheCostingCenter,
  skipCacheRequest,
  apiVersionFailure,
  ClearConfigurationCache,
  decimalPlacesRequest,
  decimalPlacesSuccess,
  decimalPlacesFailure,
} from './configurations.actions';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { EXPIRE_API_SECONDS } from '../../shared/constants/api-priority.seconds';
import moment from 'moment';
import { User } from './configurations.state';
import { Store } from '@ngrx/store';
import { selectCostingC, selectUsers } from './configurations.selector';
import { ClearInventoryCache } from 'src/app/inventory/store/inventory.actions';
import { ClearItemsCache } from 'src/app/inventory/modules/items/store/items.actions';
import { ClearUsersCache } from '../modules/users/store/user.actions';


@Injectable()
export class ConfigurationsEffects {
  constructor(
    private _actions: Actions,
    private httpClientCostingCenter: HttpClientCostingCenter,
    private httpClientCurrencies: HttpClientCurrencies,
    private toast: HotToastService,
    private translate: TranslateService,
    private httpClientGeneric: HttpClientGeneric,
    private httpClientUser: HttpClientUser,
    private httpClientCompanyOptions: HttpClientCompanyOptions,
    private store: Store
  ) { }


  skipCacheChange$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(skipCacheRequest),
        filter(value => value.skipCache),
        switchMap((data) =>
          // this.store.dispatch(ClearGlAccountsCache()),
          data.showToaster ? this.translate
            .get('general.skip_cache_enabled', {
              interval: EXPIRE_API_SECONDS.SKIP_CACHE / 60,
              interval_name: 'min',
            }) : of(false)
              .pipe(take(1))
        ),
        take(1),
        filter(message => !!message),
        tap(message => this.toast.info(message)),
        tap(() => {
          this.store.dispatch(ClearItemsCache())
          this.store.dispatch(ClearInventoryCache())
          this.store.dispatch(ClearUsersCache())
          this.store.dispatch(ClearConfigurationCache())
        })
      ),
    { dispatch: false }
  );

  getApiVersion$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(apiVersionRequest),
        nswagCatchOperator(),
        switchMap(() =>
          this.httpClientGeneric.getVersion().pipe(
            map(response =>
              response.succeeded && !!response.data
                ? apiVersionSuccess({
                  apiVersion: response.data,
                })
                : apiVersionFailure({
                  error: response.message ?? 'Unknown error',
                })
            )
          )
        )
      )
    }
    catch {
      return of(apiVersionFailure({ error: 'Unknown error' }))
    }
  }
  );

  cachedUsers$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(cacheUser.ById),
        nswagCatchOperator(),
        withLatestFrom(this.store.select(selectUsers(moment()))),
        mergeMap(([{ id }, users]) => {
          const user = users?.find(l => l?.key === id);
          return user
            ? of(cacheUser.ByIdSuccess({ user: user }))
            : this.httpClientUser.fullNameById(id).pipe(
              map(response =>
                response.succeeded && !!response.data
                  ? cacheUser.ByIdSuccess({
                    user: { key: id, value: response.data } as User,
                  })
                  : cacheUser.ByIdFailure()
              )
            );
        })
      )
    } catch {
      return of(cacheUser.ByIdFailure())
    }
  }
  );
  getActiveCurrencies$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(activeCurrenciesRequest),
        nswagCatchOperator(),
        switchMap(() =>
          this.httpClientCurrencies.activeCurrencies().pipe(
            map(response =>
              response.succeeded && !!response.data
                ? activeCurrenciesSuccess({
                  activeCurrencies: response.data,
                })
                : activeCurrenciesFailure({
                  error: response.message ?? 'Unknown error',
                })
            )
          )
        )
      )
    } catch {
      return of(activeCurrenciesFailure({ error: 'Unknown error' }))
    }
  }
  );

  logsGetAllOrFilteredRequest: CcGetAllOrFilteredRequest =
    new CcGetAllOrFilteredRequest({
      page: new DynamicPage({
        number: 1,
        size: 9999,
      }),
    });
  getActiveCostingCenters$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(activeCostingCentersRequest),
        nswagCatchOperator(),
        switchMap(() =>
          this.httpClientCostingCenter
            .getAllOrFilteredCostingCenter(this.logsGetAllOrFilteredRequest)
            .pipe(
              map(response => {
                if (response.succeeded && !!response.data) {
                  return activeCostingCentersSuccess({
                    costingCenter: response.data?.results,
                  });
                } else {
                  return activeCostingCentersFailure({
                    error: response.message ?? 'Unknown error',
                  });
                }
              }),
            )
        )
      )
    } catch {
      return of(activeCostingCentersFailure({ error: 'Unknown error' }))
    }
  }
  );


  costingCenterId$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(CacheCostingCenter.byId),
        distinctUntilChanged(),
        withLatestFrom(this.store.select(selectCostingC(moment()))),
        mergeMap(([{ costingCenterId }, selectActiveCostingCenters]) => {
          const matchingCostingCenter = selectActiveCostingCenters?.find(c => c?.id === costingCenterId);
          return matchingCostingCenter
            ? of(CacheCostingCenter.byIdSuccess({ costingCenterDto: matchingCostingCenter }))
            : this.httpClientCostingCenter
              .costingCenterGetById(costingCenterId)
              .pipe(
                nswagCatchOperator(),
                map(response =>
                  response.succeeded && !!response.data
                    ? CacheCostingCenter.byIdSuccess({
                      costingCenterDto: response.data,
                    })
                    : CacheCostingCenter.failure()
                )
              );
        })
      )
    } catch {
      return of(CacheCostingCenter.failure())
    }
  }
  );

  getDecimalPlaces$ = createEffect(() => {
    try {
      return this._actions.pipe(
        ofType(decimalPlacesRequest),
        nswagCatchOperator(),
        switchMap(() =>
          this.httpClientCompanyOptions.readCompanyOptionsById(CompanyOptionEnum.DECIMAL_ROUNDING).pipe(
            map(response =>
              response.succeeded && !!response.data
                ? decimalPlacesSuccess({
                  decimalPlaces: Number(response.data.value)
                })
                : decimalPlacesFailure({
                  error: 'Unknown error'
                })
            )
          )
        )
      )
    }
    catch {
      return of(decimalPlacesFailure({ error: 'Unknown error' }))
    }
  }
  );
}

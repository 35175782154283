import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { selectLanguage } from './dashboard/store/dashboard.selectors';
import { NavigationService } from './shared/services/navigation-service.service';
import { filter, map, mergeMap } from 'rxjs/operators'
import { Title } from '@angular/platform-browser';
import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  lang = 'sq';
  loadingRouteConfig: boolean = false;

  unsubscribe$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private store: Store,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
      if (event instanceof GuardsCheckStart) {
        this.loadingRouteConfig = true;
      }
      if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      }
    });
    navigationService.startSaveHistory();
  }

  ngOnInit() {
    this.setAppTitle();
    this.getLanguage$.subscribe();
  }
  setAppTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      if (data['title'])
        this.titleService.setTitle('devBooks - ' + this.translate.instant(data['title']));
      else this.titleService.setTitle(packageInfo.name);
    })
  }
  private get getLanguage$() {
    return this.store.select(selectLanguage).pipe(
      takeUntil(this.unsubscribe$),
      tap(language => this.translate.use(language ?? 'sq'))
    );
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}

import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  HttpClientAuth,
  RequestChangePassword,
} from '../../../shared/nswag.api';
import { BehaviorSubject, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HotToastService } from '@ngneat/hot-toast';
import { nswagCatchOperator } from '../../../shared/operators/nswag-catch-operator';
import { loadingOperator } from '../../../shared/operators/loading.operator';
import { Location } from '@angular/common';
import { InputPasswordConfig } from '../../components/password-input-component/password-input.component';

@Component({
  selector: 'app-change-password-component',
  templateUrl: './change-password-component.component.html',
  styles: [],
})
export class ChangePasswordComponentComponent {
  isLoading$ = new BehaviorSubject(false);

  fcOldPassword = new FormControl('', [Validators.required]);
  fcNewPassword = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);
  fcNewPasswordConfirm = new FormControl('', [Validators.required]);

  changePasswordForm: FormGroup = this.fb.group(
    {
      oldPassword: this.fcOldPassword,
      newPassword: this.fcNewPassword,
      newPasswordConfirm: this.fcNewPasswordConfirm,
    },
    {
      validator: this.confirmPassValidator('newPassword', 'newPasswordConfirm'),
    }
  );

  oldPasswordConfig: InputPasswordConfig = {
    inputLabel: 'auth.old_password',
    inputPlaceholder: 'auth.enter_your_password',
    autocomplete: 'password',
    formControl: this.fcOldPassword,
  };
  newPasswordConfig: InputPasswordConfig = {
    inputLabel: 'auth.new_password',
    inputPlaceholder: 'auth.enter_your_password',
    autocomplete: 'password',
    formControl: this.fcNewPassword,
  };
  confirmNewPasswordConfig: InputPasswordConfig = {
    inputLabel: 'auth.new_password_confirm',
    inputPlaceholder: 'auth.enter_your_password',
    autocomplete: 'password',
    formControl: this.fcNewPasswordConfirm,
  };

  errorMessage: string | undefined;

  constructor(
    private fb: FormBuilder,
    private httpClientAuth: HttpClientAuth,
    private toast: HotToastService,
    private _location: Location,
    private translate: TranslateService
  ) {}

  onSubmit() {
    if (this.changePasswordForm.invalid) return;
    this.errorMessage = undefined;
    const requestChangePassword: RequestChangePassword =
      new RequestChangePassword({
        oldPassword: this.fcOldPassword.value ?? undefined,
        newPassword: this.fcNewPassword.value ?? undefined,
      });
    this.httpClientAuth
      .changeLoggedInUserPassword(requestChangePassword)
      .pipe(
        nswagCatchOperator(),
        tap(value => {
          if (value?.succeeded) {
            this.toast.info(this.translate.instant('auth.password_changed'));
            this.goBack();
          } else {
            this.errorMessage = value?.message ?? 'Unknown Error';
          }
        }),
        loadingOperator(this.isLoading$)
      )
      .subscribe();
  }
  goBack() {
    this._location.back();
  }
  confirmPassValidator(key: string, confirmationKey: string) {
    return (group: FormGroup) => {
      const input = group.controls[key];
      const confirmationInput = group.controls[confirmationKey];
      if (input.value) {
        return confirmationInput.setErrors(
          input.value !== confirmationInput.value
            ? { notEquivalent: true }
            : null
        );
      }
      return null;
    };
  }
}

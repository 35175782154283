import {Injectable} from '@angular/core';
import {HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {IsApiUrl} from '../../authorization/services/auth.util';

export const DONT_THROW_EXCEPTION = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ThrowInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return !IsApiUrl(request) || !request.context.get(DONT_THROW_EXCEPTION)
      ? next.handle(request)
      : next.handle(
          request.clone({
            setHeaders: {
              'x-dont-throw-exception': 'true',
            },
          })
        );
  }
}
